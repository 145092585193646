<template>
	<div class="main-wrapper mt-4">
		<CRow>
			<CCol md="12">
				<h4 class="mb-4">
					Products
				</h4>
				<div class="typo-caption-2 mb-4">
					The information will save immediately after click “Done” in modal, It will take a few minutes to sync it. Please carefully managing.
				</div>
			</CCol>
			<CCol md="12" class="mb-4">
				<div class="label mb-2">
					Add product by
				</div>
				<BaseButtonGroup
					:value="type"
					:list="PRODUCT_TYPE_OPTIONS"
					label="Add product by"
					class="mb-4"
					@input="handleInputProductType"
				/>
			</CCol>
			<CCol md="9">
				<div class="label mb-2">
					Specific {{ compType }}
				</div>
				<div class="typo-caption-2">
					{{ compDescription }}
				</div>
			</CCol>
			<CCol md="3" class="text-right mb-4">
				<CButton
					:disabled="!type"
					color="secondary"
					@click="handleAddProduct"
				>
					Add product
				</CButton>
			</CCol>
			<CCol md="12" class="mt-4">
				<div v-if="type === MARKETING_CAMPAIGN_PRODUCT_TYPE_VALUE.PROMOTION">
					<BaseNoItemBanner
						v-if="!items.length"
						text="There is no promotion added."
					/>
					<div v-else>
						<CRow class="px-3">
							<CCol md="1">
								<span class="label">ID</span>
							</CCol>
							<CCol md="7">
								<span class="label">Promotion name</span>
							</CCol>
							<CCol md="2" />
							<CCol md="2">
								<span class="label">Period</span>
							</CCol>
						</CRow>
						<div
							class="border border-gray-400 rounded p-3"
						>
							<CRow
								v-for="promotion in items"
								:key="promotion.id"
								class="typo-body-2 mb-3"
							>
								<CCol md="1">
									<span class="typo-body-2">
										{{ promotion.id }}
									</span>
								</CCol>
								<CCol md="6">
									<div class="typo-body-2">
										{{ promotion.name }}
									</div>
									<div v-if="promotion.isDeleted">
										This promotion has been deleted.
									</div>
								</CCol>
								<CCol md="3">
									<div class="typo-body-2">
										{{ promotion.type.name }}
									</div>
									<div class="typo-caption-2">
										{{ promotion.type.reward }}
									</div>
								</CCol>
								<CCol md="2">
									<CBadge
										:color="promotion.periodStatus.color"
									>
										{{ promotion.periodStatus.title }}
									</CBadge>
								</CCol>
							</CRow>
						</div>
						<div class="text-right">
							<div
								class="btn-remove text-decoration-none font-weight-normal mt-2"
								@click="$refs['modal-confirm-clear'].open()"
							>
								Clear all selected
							</div>
						</div>
					</div>
				</div>
				<div v-else-if="type === MARKETING_CAMPAIGN_PRODUCT_TYPE_VALUE.BRAND">
					<BaseNoItemBanner
						v-if="!items.length"
						text="There is no brand added."
					/>
					<div
						v-else
						class="border border-gray-400 rounded p-3 mt-4"
					>
						<div
							v-for="brand in items"
							:key="brand.id"
							class="d-inline-flex align-items-center px-2 d-inline-block typo-body-2 bg-gray-300 rounded mr-2"
						>
							<span class="mr-1">{{ brand.name }}</span>
						</div>
					</div>
				</div>
				<div class="typo-body-2 color-black-70 mt-4">
					Total {{ totalSkuCount }} SKUs added
				</div>
				<div
					v-if="skus.length"
					class="sku-list border border-gray-400 rounded p-3 mb-5"
				>
					<div
						v-for="item in skus"
						:key="item"
						class="typo-caption-2 d-inline-block mr-1"
					>
						{{ item }};
					</div>
				</div>
			</CCol>
		</CRow>

		<ModalSkuList
			v-if="MARKETING_CAMPAIGN_PRODUCT_TYPE_VALUE.SKU"
			ref="modal-sku"
			:sku-list="items"
			title="Add product by SKU"
			title-confirm="Confirm to add product?"
			description-confirm="Confirm to add product?"
			need-confirmation
			@onConfirm="handleConfirmSKU"
		/>
		<ModalBrand
			v-if="MARKETING_CAMPAIGN_PRODUCT_TYPE_VALUE.BRAND"
			ref="modal-brand"
			:list="items"
			title="Add product by brand"
			title-confirm="Confirm to add product?"
			description-confirm="Confirm to add product?"
			need-confirmation
			@onConfirm="handleConfirmBrand"
		/>
		<ModalPromotion
			v-if="MARKETING_CAMPAIGN_PRODUCT_TYPE_VALUE.PROMOTION"
			ref="modal-promotion"
			:list="items"
			title="Add product by promotion"
			title-confirm="Confirm to add product?"
			description-confirm="Confirm to add product?"
			need-confirmation
			data-test-id="modal-promotion"
			@onConfirm="handleConfirmPromotion"
		/>
		<BaseModalConfirmDelete
			ref="modal-confirm-discard"
			title="Discard?"
			description="You will be lost all added information. Are you sure to discard?"
			delete-button-text="Discard"
			@onSuccess="handleDiscard"
		/>
		<BaseModalConfirmDelete
			ref="modal-confirm-clear"
			title="Clear all selected?"
			description="By clearing all selected, the information will be remove from the list and marketing campaign."
			delete-button-text="Clear all"
			@onSuccess="handleClear('promotion')"
		/>
	</div>
</template>

<script>
import { mapActions } from 'vuex';
import ModalSkuList from '@/components/ModalSkuList.vue';
import ModalBrand from '@/components/ModalBrand.vue';
import ModalPromotion from '@/components/ModalPromotion.vue';
import BaseModalConfirmDelete from '@/components/BaseModalConfirmDelete.vue';
import {
	PRODUCT_TYPE_OPTIONS,
	MARKETING_CAMPAIGN_PRODUCT_TYPE_VALUE,
} from '../enums/marketings';
import { numberFormat } from '../assets/js/helpers';

export default {
	name: 'FormMarketingCampaignProductManagement',
	components: {
		ModalSkuList,
		ModalBrand,
		ModalPromotion,
		BaseModalConfirmDelete,
	},
	props: {
		productType: {
			type: String,
			default: null,
		},
		productItems: {
			type: Array,
			default: () => [],
		},
		productSkus: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			PRODUCT_TYPE_OPTIONS,
			MARKETING_CAMPAIGN_PRODUCT_TYPE_VALUE,
			type: null,
			nextType: null,
			currentType: null,
			items: [],
			skus: [],
		};
	},
	computed: {
		totalSkuCount() {
			return numberFormat(this.skus.length);
		},
		compType() {
			if (this.type === MARKETING_CAMPAIGN_PRODUCT_TYPE_VALUE.SKU) {
				return this.type.toUpperCase();
			}
			return this.type;
		},
		compDescription() {
			const hasDeletedPromotion = this.type === MARKETING_CAMPAIGN_PRODUCT_TYPE_VALUE.PROMOTION &&
				this.items.some((promotion) => promotion.isDeleted);

			if (this.type === MARKETING_CAMPAIGN_PRODUCT_TYPE_VALUE.SKU) {
				return 'Add/Edit product by specific SKU by clicking "Add product” or “Edit product" then adjust them in the modal.';
			} else if (this.type === MARKETING_CAMPAIGN_PRODUCT_TYPE_VALUE.BRAND) {
				return 'Add/Edit all product by brands by clicking "Add product” or “Edit product”. To Re-sync the product just click "Edit product" then click "Done".';
			} else if (this.type === MARKETING_CAMPAIGN_PRODUCT_TYPE_VALUE.PROMOTION && !hasDeletedPromotion) {
				return 'You can only add the SKUs by selected promotion, disable editing. By removing the SKUs in the promotion have to “Clear all” then add them again.';
			} else if (this.type === MARKETING_CAMPAIGN_PRODUCT_TYPE_VALUE.PROMOTION && hasDeletedPromotion) {
				return 'You can only add the SKUs by selected promotion, by removing the SKUs in the promotion have to “Clear all” then add them again.';
			}

			return null;
		},
	},
	created() {
		this.type = this.productType;
		this.currentType = this.productType;
		this.skus = this.productSkus;
		this.items = this.productItems;
	},
	methods: {
		...mapActions({
			addMarkertingCampaignProducts: 'marketingCampaigns/addMarketingCampaignProducts',
		}),
		handleDiscard() {
			this.handleClear(this.nextType);
		},
		handleInputProductType(type) {
			if (this.currentType !== type && (this.skus.length > 0 || this.items.length > 0)) {
				this.$refs['modal-confirm-discard'].open();
				this.nextType = type;
			} else {
				this.currentType = this.type;
				this.type = type;
			}
		},
		async handleClear(type) {
			const id = this.$route.params.id;
			const params = {
				item_type: type,
				items: [],
			};
			await this.addMarkertingCampaignProducts({ id, params });
		},
		async handleConfirmSKU(skus) {
			const id = this.$route.params.id;
			const params = {
				item_type: MARKETING_CAMPAIGN_PRODUCT_TYPE_VALUE.SKU,
				items: skus,
			};
			this.skus = skus;
			await this.addMarkertingCampaignProducts({ id, params });
		},
		async handleConfirmBrand(brands) {
			const id = this.$route.params.id;
			const params = {
				item_type: MARKETING_CAMPAIGN_PRODUCT_TYPE_VALUE.BRAND,
				items: brands.map((brand) => brand.id),
			};
			this.brands = brands;
			await this.addMarkertingCampaignProducts({ id, params });
		},
		async handleConfirmPromotion(promotions) {
			const id = this.$route.params.id;
			const params = {
				item_type: MARKETING_CAMPAIGN_PRODUCT_TYPE_VALUE.PROMOTION,
				items: promotions.map((promotion) => promotion.id),
			};
			this.promotions = promotions;
			await this.addMarkertingCampaignProducts({ id, params });
		},
		handleAddProduct() {
			switch (this.type) {
				case MARKETING_CAMPAIGN_PRODUCT_TYPE_VALUE.SKU:
					this.$refs['modal-sku'].open();
					break;
				case MARKETING_CAMPAIGN_PRODUCT_TYPE_VALUE.BRAND:
					this.$refs['modal-brand'].open();
					break;
				case MARKETING_CAMPAIGN_PRODUCT_TYPE_VALUE.PROMOTION:
					this.$refs['modal-promotion'].open();
					break;
				default:
					break;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
	.sku-list {
		max-height: rem(140);
		overflow-y: auto;
	}
</style>
