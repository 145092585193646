<template>
	<div>
		<BaseLoading v-if="edit.isLoading" is-full-page />
		<div v-else>
			<div class="mkt-container">
				<h4>
					{{ edit.data.nameEn }}
				</h4>
				<div>
					<span class="typo-body-2">Last complete synced: </span>
					<span class="typo-body-2 color-black-45">
						{{ edit.data.syncedAt }}
					</span>
				</div>
				<div>
					<span class="typo-body-2">Last updated: </span>
					<router-link
						:to="{ name: 'UserEdit', params: { id: createdBy.actorId }}"
					>
						{{ createdBy.actorName }}
					</router-link>
					<span class="typo-body-2 color-black-45">
						| {{ createdBy.createdAt | datetimeFormat }}
					</span>
				</div>
				<div class="mb-4">
					<span class="typo-body-2">Created updated: </span>
					<router-link
						:to="{ name: 'UserEdit', params: { id: updatedBy.actorId }}"
					>
						{{ updatedBy.actorName }}
					</router-link>
					<span class="typo-body-2 color-black-45">
						| {{ updatedBy.updatedAt | datetimeFormat }}
					</span>
				</div>
				<div class="mkt-sync typo-body-2">
					Sync status:
					<MarketingCampaignSyncStatus
						:status="edit.data.syncStatus"
						class="color-black-45 ml-2 mr-4"
					/>
					<CButton
						:disabled="!edit.data.items.length"
						class="btn-re-sync"
						color="tertiary"
						data-test-id="btn-re-sync"
						@click="handleReSyncClick"
					>
						Re-sync product
					</CButton>
				</div>
			</div>
			<div class="tabs-container-large">
				<div class="tabs-wrapper">
					<CTabs
						:active-tab="activeTab"
						@update:activeTab="handleActiveTab"
					>
						<CTab title="General info">
							<FormMarketingCampaignGeneralInfo
								:is-submitting="edit.isUpdating"
								:name-en="edit.data.nameEn"
								:name-th="edit.data.nameTh"
								:slug="edit.data.slug"
								:is-active="edit.data.isActive"
								:is-visible="edit.data.isVisible"
								:start-date="edit.data.startDate"
								:start-time="edit.data.startTime"
								:end-date="edit.data.endDate"
								:end-time="edit.data.endTime"
								:widgets="edit.data.widgets"
								:banners="edit.data.banners"
								:description-top="edit.data.descriptionTop"
								:description-bottom="edit.data.descriptionBottom"
								:title-en="edit.data.seo.titleEn"
								:title-th="edit.data.seo.titleTh"
								:keyword-en="edit.data.seo.keywordEn"
								:keyword-th="edit.data.seo.keywordTh"
								:description-en="edit.data.seo.descriptionEn"
								:description-th="edit.data.seo.descriptionTh"
								:period-status="edit.data.periodStatus"
								is-edit
								@onConfirm="handleEdit"
								@onRemove="$refs['modal-confirm-delete'].open()"
							/>
						</CTab>
						<CTab title="Product management">
							<FormMarketingCampaignProductManagement
								:product-type="edit.data.type"
								:product-items="edit.data.items"
								:product-skus="edit.data.skus"
							/>
						</CTab>
					</CTabs>
				</div>
			</div>
		</div>
		<BaseModalConfirmDelete
			ref="modal-confirm-delete"
			title="Remove this marketing campaign?"
			description="Deleting this marketing campaign will affect immediately."
			is-confirm-required
			:handle-remove="removeMarketingCampaign.bind(null, id)"
		/>
		<BaseModalLoading ref="modal-loading" modal-title="Syncing" />
	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import FormMarketingCampaignGeneralInfo from '@/components/FormMarketingCampaignGeneralInfo.vue';
import FormMarketingCampaignProductManagement from '@/components/FormMarketingCampaignProductManagement.vue';
import MarketingCampaignSyncStatus from '@/components/MarketingCampaignSyncStatus.vue';
import { datetimeFormat, convertDateTimeToUTC } from '../assets/js/helpers';
import { reSyncCampaignAPI } from '../services/api/marketingCampaigns.api';


export default {
	name: 'MarketingCampaignEdit',
	filters: {
		datetimeFormat,
	},
	components: {
		FormMarketingCampaignGeneralInfo,
		FormMarketingCampaignProductManagement,
		MarketingCampaignSyncStatus,
	},
	data() {
		const activeTab = this.$route.query.activeTab
			? Number(this.$route.query.activeTab)
			: 0;
		return {
			activeTab,
			id: this.$route.params.id,
		};
	},
	computed: {
		...mapState('marketingCampaigns', {
			edit: 'edit',
		}),
		createdBy() {
			return this.edit.data.logs.length
				? this.edit.data.logs[0]
				: {};
		},
		updatedBy() {
			return this.edit.data.logs.length
				? this.edit.data.logs.slice(-1).pop()
				: {};
		},
	},
	watch: {
		by(value, oldValue) {
			if (value !== oldValue && this.skus.length > 0) {
				this.$refs['modal-confirm-change'].open();
			}
		},
	},
	async created() {
		await this.getMarketingCampaign(this.id);
	},
	methods: {
		...mapActions({
			getMarketingCampaign: 'marketingCampaigns/getMarketingCampaign',
			updateMarketingCampaign: 'marketingCampaigns/updateMarketingCampaign',
			removeMarketingCampaign: 'marketingCampaigns/removeMarketingCampaign',
			showToast: 'toast/showToast',
		}),
		handleActiveTab(activeTab) {
			this.$router.push({ query: { activeTab } });
		},
		async handleEdit(formData) {
			const params = {
				name_th: formData.generalInfo.nameTh,
				name_en: formData.generalInfo.nameEn,
				slug: formData.generalInfo.slug,
				is_active: !!formData.generalInfo.isActive,
				is_visible: !!formData.generalInfo.isVisible,
				start_at: formData.activeDate.startDate
					? convertDateTimeToUTC(formData.activeDate.startDate, formData.activeDate.startTime)
					: null,
				end_at: formData.activeDate.endDate
					? convertDateTimeToUTC(formData.activeDate.endDate, formData.activeDate.endTime)
					: null,
				seo: {
					title_th: formData.seo.titleTh,
					title_en: formData.seo.titleEn,
					description_th: formData.seo.descriptionTh,
					description_en: formData.seo.descriptionEn,
					keyword_th: formData.seo.keywordTh,
					keyword_en: formData.seo.keywordEn,
				},
				description_top: formData.content.descriptionTop,
				description_bottom: formData.content.descriptionBottom,
				banners: formData.content.banners
					? formData.content.banners
						.filter((banner) => banner)
						.map((banner) => banner.id)
					: [],
				widgets: formData.linkToHomepage.widgets
					? formData.linkToHomepage.widgets.map((widget) => widget.id)
					: [],
			};

			await this.updateMarketingCampaign({ id: this.id, params });
		},
		async handleReSyncClick() {
			this.$refs['modal-loading'].open();

			try {
				await reSyncCampaignAPI(this.id);

				this.showToast({
					content: 'The campaign is syncing. It could take up to 30 minutes for the result to take effect.',
					header: 'Success',
					type: 'success',
				});

				this.$router.go(0);
			} catch {
				this.showToast({
					content: this.$t('global.errorMessage'),
					header: 'Unable to re-sync',
					type: 'danger',
				});
			} finally {
				this.$refs['modal-loading'].close();
			}
		},
	},
};
</script>

<style lang="scss" scoped>
	.mkt-container {
		position: relative;
	}

	.mkt-sync {
		position: absolute;
		top: 0;
		right: 0;
	}
</style>
